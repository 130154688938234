@import "../../../styles/_breakpoints.scss";
@import "../../../styles/_colors.scss";

.side-container {
    margin-top: 12px;
    margin-bottom: 12px;
    padding-top: 30px !important;
    padding-bottom: 30px !important;

    @include media-breakpoint-up(sm) {
        padding-top: 60px !important;
        padding-bottom: 60px !important;
        padding-left: 40px !important;
        padding-right: 40px !important;
    }

    @include media-breakpoint-up(lg) {
        padding-left: 90px !important;
        padding-right: 90px !important;
    }

    &.side-container-dark {
        background-color: $dark-bg;
        color: $dark-color;

        h1, h2, h3, h4, h5, h6, h7 {
            color: $dark-color;
        }

        a:not(.btn) {
            color: lighten($primary, 15%);
        }
    }

    &.side-container-transparent {
        background-color: transparent;
    }

    &.side-container-left {
        border-top-right-radius: 60px;
        border-bottom-right-radius: 60px;
    }

    &.side-container-right {
        border-top-left-radius: 60px;
        border-bottom-left-radius: 60px;
    }
}



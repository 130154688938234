@import "../../../styles/_colors.scss";

.event-type-color-block {
    width: 10px;
    height: 100%;
    margin-right: 3px;
    border: 1px solid $black;
    min-height: 1rem;
    // Transparent blocks need to be transparent with no border, but take up the same space.
    &.event-type-color-block-transparent {
        background-color: transparent;
        border: 1px solid transparent;
    }
}

@import "../../styles/_breakpoints.scss";
@import "../../styles/_colors.scss";

.main-container {
    padding-top: 12px !important;
    padding-bottom: 12px !important;

    &.main-container-main {
        background-color: $main-bg;
        color: $main-color;
    }

    &.main-container-transparent {
        background-color: transparent;
    }

    @include media-breakpoint-up(sm) {
        //margin-top: 10px;
        /*border-radius: 10px 10px;*/
    }

    @include media-breakpoint-down(xs) {
        // Override the default padding added by .container-fluid on xs screens.
        &.container-fluid {
            padding-left: 5px;
            padding-right: 5px;
        }
    }
}



// Pull in the colour theme.
@import "../../../styles/_colors.scss";

// Pull in the fonts.
@import "../../../styles/_fonts.scss";

// Customize some CoreUI rules.
$navbar-height: 90px;

// Boostrap and coreui variables.
@import "../../../../node_modules/bootstrap/scss/mixins";
@import "../../../../node_modules/@coreui/coreui/scss/variables";

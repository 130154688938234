@import "../../../styles/_colors.scss";

.header-search-input {
    .search-input-input {
        background-color: $theschoolbus-lightgray;
        color: $secondary;
        border-color: $gray-300;
    }

    .search-input-button {
        &:not(:hover) {
            background-color: $theschoolbus-lightgray;
            border-color: $gray-300;
        }
    }
}

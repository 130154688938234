@import "../../styles/_breakpoints.scss";
@import "../../styles/_colors.scss";

.sidebar {
    background-color: $theschoolbus-blue;
    color: $white;
    border-top-right-radius: 50px;
    border-bottom-right-radius: 50px;

    padding-top: 40px;

    .menu-nav {
        min-height: 0px !important; // Overide the min-height: 100% from coreui as we use multiple navs to manage our sidebar.
    }

    @include media-breakpoint-up(md) {
        .menu-nav {
            display: none !important;
        }
    }

    .nav-link {
        //color: $white

        &:hover {
            background-color: $theschoolbus-aquamarine;
            color: $theschoolbus-blue;
        }

        &.active {
            background-color: $theschoolbus-aquamarine;
            border-radius: 8px;
            color: $white;
            font-weight: bold;

            .nav-icon {
                color: $white;
            }
        }
    }

    .sidebar-minimizer {
        background-color: transparent;
        border-bottom-right-radius: 50px;

        &:hover {
            background-color: $theschoolbus-aquamarine;
        }
    }
}

.sidebar-minimized {
    .sidebar {
        .nav-link {
            &:hover {
                background-color: $theschoolbus-aquamarine !important;
            }
        }
    }

    .sidebar-minimizer {
        background-color: transparent !important;
    }
}
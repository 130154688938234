@import "../../styles/_colors.scss";
@import "../../styles/_breakpoints.scss";

.calendar-event-modal {
    // Special sizing for md screens
    @include media-breakpoint-only(md) {
        max-width: 700px;
    }

    .modal-content {
        border-color: $theschoolbus-blue;
    }

    .modal-header {
        border-bottom-style: none;
        background-color: $theschoolbus-blue;
        color: $white;
        padding-left: 0px;
        padding-top: 0px;
        padding-bottom: 0px;

        .calendar-event-modal-header-event-type-color-block {
            width: 25px;
        }

        .calendar-event-modal-real-header {
            padding-left: 16px;
            padding-top: 16px;
            padding-bottom: 16px;
        }


        .close {
            color: $white;
        }
    }


    .modal-body {
        @include media-breakpoint-up(md) {
            padding-right: 0px;
        }
    }

    .modal-title {
        width: 100%;

        h2 {
            color: $white;
        }
    }

    .calendar-event-modal-time {
        color: $gray-200;
    }

    .calendar-event-modal-event-type {
        font-size: 1rem;
        font-weight: normal;
        color: $gray-200;
    }

    .calendar-event-modal-promotional-image-container {
        padding: 4px 4px 4px 4px;
        text-align: center;

        .calendar-event-modal-promotional-image {
            max-height: 300px;
        }
    }


    .calendar-event-modal-sidebar {
        display: flex;
        flex-direction: column;
        background-color: $theschoolbus-blue;
        color: $white;
        padding-top: 15px;
        padding-bottom: 15px;
        padding-left: 8px;
        padding-right: 8px;
        border-radius: 15px;

        @include media-breakpoint-up(md) {
            height: 100%;
            border-top-right-radius: 0px;
            border-bottom-right-radius: 0px;
        }

        .calendar-event-modal-urls {
            //flex-grow: 1;
            margin-bottom: 4rem;

            .calendar-event-modal-url-button {
                width: 100%;
                margin-bottom: 0.2rem;

                // Force the breaking of really long words on these buttons as sometimes people are putting in long URLs that don't contain
                // spaces.
                word-break: break-word;
            }
        }

        .calendar-event-modal-subscribe {
            flex-grow: 1;
            margin-bottom: 2rem;

            .calendar-event-modal-calendar-button {
                width: 100%;
                margin-bottom: 0.2rem;
            }
        }

        .calendar-event-modal-providers {
            .calendar-event-modal-providers-heading {
                color: $gray-500;
            }

            .calendar-event-modal-provider-no-image {
                height: 100%;
                min-height: 30px;
                margin-bottom: 2px;
                font-size: 1.2rem;
                font-weight: bold;
                text-align: center;
                cursor: pointer;
            }

            .calendar-event-modal-provider-image {
                max-height: 120px;
                cursor: pointer;
            }
        }
    }
}

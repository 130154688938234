﻿// Universal tweaks to HTML elements that are not bootstrap or component specific.
//

@import "./_colors.scss";

// Headings
h1 {
    color: $secondary;
    border-bottom-color: $primary;
    border-bottom-style: solid;
    border-bottom-width: 1px;
    font-weight: 800;
}

h2 {
    margin-top: 0.4rem;
    color: $secondary;
    border-bottom-color: $primary;
    border-bottom-style: solid;
    border-bottom-width: 1px;
    font-weight: 800;
}

h3 {
    margin-top: 0.4rem;
    color: $secondary;
    border-bottom-color: $primary;
    border-bottom-style: solid;
    border-bottom-width: 1px;
    font-weight: 800;
}
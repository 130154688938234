﻿@import "_colors.scss";

// Universal tweaks to bootstrap styles that are not component specific.
//

.card-title {
    color: $secondary;
    border-bottom-color: $primary;
    border-bottom-style: solid;
    border-bottom-width: 1px;
}

.btn-secondary, .btn-outline-secondary {
    &:hover {
        // Darkened to 7.1 instead of 7.5 at specific request from HB: "For navy buttons, can the hover over colour be 5% lighter?"
        // I've calcualted 7.1 as a rounded result of (7.5 * 0.95) to handle 5% lighter.
        background-color: darken($secondary, 7.1%);
    }
}

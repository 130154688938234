@import "../../../styles/_colors.scss";
@import "../../../styles/_breakpoints.scss";

$weekend-color: $gray-200;

.calendar-day {
    min-height: 50px;
    height: 100%;
    border-color: $theschoolbus-darkgray;
    border-width: 1px;
    border-style: solid;
    // Let the vents within the day manage their own margins.
    padding-left: 0px;
    padding-right: 0px;

    .calendar-day-date {
        color: $gray-600;
        padding-left: 4px;

        &.calendar-day-date-past {
            text-decoration: line-through;
        }
    }

    .calendar-day-events {
        width: 100%;
        padding-left: 2px;
        padding-right: 2px;
    }
    // Highlight today with an orange border.
    &.calendar-day-today {
        border-color: $primary;
        border-width: 2px;
    }
    // Gray out the weekend (and shrink on mobile where it has its own row).
    &.calendar-day-saturday {
        background-color: $weekend-color;
    }

    &.calendar-day-sunday {
        background-color: $weekend-color;
    }

    // On bigger screens have a min size of 100 and hide the day names as they will be shown
    // in a single header.
    @include media-breakpoint-up(sm) {
        min-height: 100px;

        .calendar-day-date-day-name {
            display: none;
        }
    }
}

@import "../../../styles/_colors.scss";

.install-pwa-prompt {
    position: fixed;
    right: 0px;
    bottom: 0px;
    margin-left: 5px;
    margin-right: 5px;
    max-width: 400px;
    //background-color: transparentize($dark-bg, 0.15) !important;

    h1, h2, h3, h4 {
        color: $dark-color;
    }

    .install-pwa-prompt-title-image {
        background-image: url("TheSchoolYear_OnBlue_RGB_less_padding.svg");
        background-size: contain;
        background-repeat: no-repeat;
        width: 100%;
        height: 40px;
        min-width: 200px;
        margin-bottom: 4px;
    }

    .install-pwa-prompt-ios-share-icon {
        width: 1.5rem;
        height: 1.5rem;
        padding-bottom: 0.2rem;
        display: inline-block;
    }
}

@import "../../../styles/_colors.scss";
@import "../../../styles/_breakpoints.scss";

.calendar-week {
    /*border-color: $theschoolbus-darkgray;
    border-width: 1px;
    border-style: solid;*/

    .calendar-week-all-days {
        display: flex;

        @include media-breakpoint-up(sm) {
            > .col-sm {
                min-width: 14.28571% !important;
                max-width: 14.28571% !important;
                overflow-x: hidden;
            }
        }
    }
}

@import "../../../styles/_colors.scss";
@import "../../../styles/_breakpoints.scss";


$multiday-faded-bgcolor: transparentize($theschoolbus-blue, 0.3);

.calendar-day-event {
    min-width: 100%;
    //max-width: 100%;
    background-color: $theschoolbus-blue;
    border-color: darken($theschoolbus-blue, 20%);
    color: $white;
    border-style: solid;
    border-width: 1px;
    margin-bottom: 2px;
    cursor: pointer;

    .calendar-day-event-time {
        color: $gray-300;
        font-size: 80%;
    }

    &.calendar-day-event-starts-before {
        //border-left-style: none;
        border-left-color: $theschoolbus-blue;
        margin-left: -3px; // Bigger than required so we get full overlap over day dividing lines.
        padding-left: 3px;
        // Render the text as transparent in order to prevent it being repeated each day.
        .calendar-day-event-time, .calendar-day-event-text {
            color: transparent;
        }
    }
    // Special styles for multi day events.
    &.calendar-day-event-multiday {
        // Days 3 to 3rd to last day should be faded.
        &.calendar-day-event-starts-before.calendar-day-event-ends-after {
            &:not(.calendar-day-event-multiday-second-day) {
                &:not(.calendar-day-event-multiday-second-to-last-day) {
                    background-color: $multiday-faded-bgcolor;
                    border-left-color: transparent;
                    border-right-color: transparent;
                }
            }
        }
        // Second day should be a gradiant (unless its only a 2 day event.)
        &.calendar-day-event-multiday-second-day.calendar-day-event-ends-after {
            background: linear-gradient(90deg, $theschoolbus-blue, $multiday-faded-bgcolor);
            border-right-color: transparent;
        }
        // Second to last day should be a gradiant (unless its only a 2 day event).
        &.calendar-day-event-multiday-second-to-last-day.calendar-day-event-starts-before {
            background: linear-gradient(90deg, $multiday-faded-bgcolor, $theschoolbus-blue);
            border-left-color: transparent;
        }
        // Three day events end up with the second day and second to last day both falling on the same day so
        // we need to give it it's own unique style of a double gradiant.
        &.calendar-day-event-multiday-second-day.calendar-day-event-ends-after.calendar-day-event-multiday-second-to-last-day.calendar-day-event-starts-before {
            background: linear-gradient(90deg, $theschoolbus-blue, $multiday-faded-bgcolor, $multiday-faded-bgcolor, $theschoolbus-blue);
        }
    }

    &.calendar-day-event-ends-after {
        //border-right-style: none;
        border-right-color: $theschoolbus-blue;
        margin-right: -3px; // Bigger than required so we get full overlap over day dividing lines.
        padding-right: 3px;
    }
}

// On a Monday, we repeat most the calendar details if we're carrying something forward form the previous week.
.calendar-day-monday {
    .calendar-day-event {
        &.calendar-day-event-starts-before {
            .calendar-day-event-time {
                color: $gray-300;
            }

            .calendar-day-event-text {
                color: $white;
            }
        }
    }
}

// On Small screens we show one day per row, so we want o repeat everything every day but unlike on a Monday
// we don't also want to show the colour block.
@include media-breakpoint-down(xs) {
    .calendar-day-event {
        &.calendar-day-event-starts-before {
            .calendar-day-event-time {
                color: $gray-300;
            }

            .calendar-day-event-text {
                color: $white;
            }
        }
    }
}
@import "../../styles/_breakpoints.scss";

.login-partial-page {

    .login-partial-page-card {
        @include media-breakpoint-up(md) {
            max-width: 80%;
            margin-left: auto;
            margin-right: auto;
        }
    }
}

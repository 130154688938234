@import "../../styles/_colors.scss";

.about-page {
    .about-page-section-1 {
        background-color: $dark-bg;
        color: $dark-color;
        padding-top: 1rem;
        padding-bottom: 1.5rem;

        h1, h2, h3, h4, h5, h6, h7, h8, h9 {
            color: $dark-color;
            //border-bottom-color: lighten($primary, 15%);
        }

        a:not(.btn) {
            color: lighten($primary, 15%);
        }
    }

    .about-page-section-2 {
        padding-top: 1rem;
        padding-bottom: 1.5rem;
    }

    .about-page-section-3 {
        background-color: $dark-bg;
        color: $dark-color;
        padding-top: 1rem;
        padding-bottom: 1.5rem;

        h1, h2, h3, h4, h5, h6, h7, h8, h9 {
            color: $dark-color;
        }
    }

    .about-page-section-4 {
        padding-top: 1rem;
        padding-bottom: 1.5rem;
    }
}

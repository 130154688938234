@import "../../styles/_colors.scss";
@import "../../styles/_fonts.scss";

.calendar-home {
    .calendar-home-list-hidden-past-items-message {
        background-color: $gray-100;
        border: 1px solid $gray-200;
        //color: $white;
        padding-left: 15px;
        padding-right: 15px;
        padding-top: 8px;
        padding-bottom: 8px;
        margin-bottom: 15px;
        text-align: center;
        font-size: 0.8rem;
        cursor: pointer;
    }

    .calendar-home-title-filter-button-group {
        font-family: Poppins;

        .btn:first-child {
            border-top-left-radius: 15px;
            border-bottom-left-radius: 15px;
            padding-left: 15px;
        }

        .btn:last-child {
            border-top-right-radius: 15px;
            border-bottom-right-radius: 15px;
            padding-right: 15px;
        }
    }
}

@import "../../styles/_colors.scss";

.tag-selector {
    .tag-selector-item {
        margin-right: 2px;
        margin-bottom: 2px;

        border-radius: 15px;

        &.btn-outline-primary {
            &:not(:hover) {
                background-color: transparentize($white, 0.8);
                color: lighten($primary, 15%);
            }
        }
    }
}

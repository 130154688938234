@import "../../styles/_colors.scss";

.calendar-event-card-item {
    cursor: pointer;
    padding-left: 0px;
    padding-right: 0px;

    .calendar-event-card-item-time {
        color: $gray-600;
        font-size: 80%;
    }

    .calendar-event-card-item-provider-logo {
        max-height: 30px;
    }
    
    &.calendar-event-card-item-starts-before {
    }

    &.calendar-event-card-item-ends-after {
    }
}


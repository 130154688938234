@import "../../../styles/_colors.scss";
@import "../../../styles/_breakpoints.scss";

.calendar-weekday-names {
    // Smaller screens don't show the weekday names as their own row.
    display: none;

    .calendar-weekday-names-day {
        padding-left: 4px;
    }
    // On bigger screens we want to show everything.
    @include media-breakpoint-up(sm) {
        display: flex;

        > .col {
            min-width: 14.28571%;
            max-width: 14.28571%;
        }
    }
}

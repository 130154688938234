@import "../../styles/_colors.scss";

.footer {
    background-color: $dark-bg;
    color: $dark-color;
    text-align: center;
    display: flex;
    justify-content: center;
    font-size: 0.8rem;
    margin-top: 1rem;

    .footer-nav {
        justify-content: center;

        .nav-link {
            color: $dark-color;
        }

        .nav-icon {
            display: none;
        }

        flex-direction: row;
    }

    .footer-company-text {
        min-width: 100%;
        font-size: 0.6rem;
        padding-bottom: 0.1rem;
    }
}

@import "../../styles/_breakpoints.scss";
@import "../../styles/_colors.scss";

.calendar-month-headline {
    color: white;
    background-image: url("./headline_default_image.png");
    background-size: cover;
    background-repeat: no-repeat;
    background-position-x: left;
    background-position-y: top;

    &.calendar-month-headline-calendar {
        .calendar-month-headline-picture {
            @include media-breakpoint-up(lg) {
                min-height: 200px;
            }
        }
    }

    .calendar-month-headline-summary {
        background-color: $dark-bg;
        color: $dark-color;
        margin-top: 8px;
        margin-bottom: 8px;
        margin-left: 8px;
        margin-right: 8px;
        padding-top: 15px;
        padding-bottom: 15px;
        padding-left: 15px;
        padding-right: 15px;
        min-height: 200px;
        // Headings inside the summary should use $dark-color text.
        h1, h2, h3, h4, h5, h6 h7 h8, h9 {
            color: $dark-color;
        }
    }

    .calendar-month-headline-navigation {
        padding-top: 15px;
        padding-bottom: 15px;
        padding-left: 15px;
        padding-right: 15px;

        .calendar-month-headline-navigation-month-text {
            width: 100%;
            height: 100%;
            font-size: 1.1rem;
            text-align: center;
            //text-shadow: 1px 1px 2px $theschoolbus-blue;

            .calendar-month-headline-navigation-month-text-inner {
                background-color: transparentize($dark-bg, 0.8);
                padding-top: 5px;
                padding-bottom: 5px;
                padding-left: 15px;
                padding-right: 15px;
                border-radius: 20px;
                margin-top: -5px;
                display: inline-block;
            }

            @include media-breakpoint-up(md) {
                font-size: 1.6rem;
            }
        }

        .calendar-month-headline-navigation-previous-button {
            border-top-left-radius: 1rem;
            border-bottom-left-radius: 1rem;

            &:not(:hover) {
                background-color: $white;
            }
        }

        .calendar-month-headline-navigation-next-button {
            border-top-right-radius: 1rem;
            border-bottom-right-radius: 1rem;

            &:not(:hover) {
                background-color: $white;
            }
        }
    }
}

@import "../../styles/_colors.scss";

.calendar-day-card {
    &.calendar-day-card-today {
        border-color: $primary;
    }

    &.calendar-day-card-past {
        background-color: $gray-100;

        .calendar-event-card-item {
            background-color: $gray-100;
        }

        .card-title {
            text-decoration: line-through;
        }
    }
}


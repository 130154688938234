@import "../../styles/_colors.scss";
@import "../../styles/_breakpoints.scss";

.calendar-home-sticky-toolbar {
    &.sticky-toolbar-stuck {
        background-color: $white;

        @include media-breakpoint-down(xs) {
            .calendar-home-title-filter-button-group {
                display: none;
            }

            .calendar-home-title-filter-dropdown {
                display: none;
            }
        }
    }
}

